// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/contact/success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-special-jsx": () => import("./../../../src/pages/special.jsx" /* webpackChunkName: "component---src-pages-special-jsx" */),
  "component---src-pages-tour-jsx": () => import("./../../../src/pages/tour.jsx" /* webpackChunkName: "component---src-pages-tour-jsx" */)
}

